import { Response } from "~/components/poll";

const POLL_RESPONSES: Response[] = [
  {
    color: "lime",
    text: "Very accurate",
    response: "VERY_ACCURATE"
  },
  {
    color: "yellow",
    text: "Mostly accurate",
    response: "MOSTLY_ACCURATE"
  },
  {
    color: "orange",
    text: "Somewhat accurate",
    response: "SOMEWHAT_ACCURATE"
  },
  {
    color: "red",
    text: "Not very accurate",
    response: "NOT_VERY_ACCURATE"
  }
];

export default POLL_RESPONSES;
