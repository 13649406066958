import React, { MouseEvent, useState, useContext } from "react";

import clsx from "clsx";

import { post } from '~/helpers/api';
import { MainContext } from "~/context";

import Button from "~/components/button";


interface CheckProps {
  index: number;
  selection: number;
};

interface PollProps {
  color: "ms" | "hs";
  disabled?: boolean;
  responses: Response[];
  question: string;
};

export interface Response {
  color: "lime" | "yellow" | "orange" | "red";
  text: string;
  response: string;
};

const Check = ({ index, selection }: CheckProps): JSX.Element => {
  return (
    <>
      <i className={clsx("fas fa-check transition-color duration-200", { "text-white": selection !== index })} />&nbsp;
    </>
  );
};

const Poll = ({ color, responses, question, disabled=false }: PollProps) => {
  const { classCode } = useContext(MainContext);

  const [errors, setErrors] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selection, setSelection] = useState<number>(-1);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const isMs: boolean = color === "ms";

  const handleSubmit = async (e: MouseEvent) => {
    e.preventDefault();
    setLoading(true);

    const classLevel: string | null = localStorage.getItem("class_type");

    if (!classLevel || !classCode) {
      setLoading(false);
      setErrors(true);
      setSubmitted(true);
      return;
    }

    try {
      await post("/createPollResponse", {
        code: classCode,
        level: classLevel,
        response: responses[selection].response,
      })

      localStorage.removeItem('class_code');
      setLoading(false);
      setErrors(false);
      setSubmitted(true);
    } catch (err) {
      setLoading(false);
      setErrors(true);
      setSubmitted(true);
    }
  };

  return (
    <div
      className={clsx(
        "p-5 rounded-lg shadow",
        {
          "bg-purple-100": isMs,
          "bg-indigo-100": !isMs,
        }
      )}
    >
      <p className="font-medium text-lg">{question}</p>
      <div className="mt-5 flex flex-col space-y-1 bg-white p-5 rounded-lg">
        {!submitted &&
          <>
            {responses.map((response: Response, i: number) => (
              <Button
                action="tertiary"
                className="text-lg font-medium"
                color={response.color}
                key={`response-${i}`}
                onClick={() => setSelection(i)}
                title={response.text}
              >
                <Check selection={selection} index={i} />{response.text}
              </Button>
            ))}
          </>
        }
        <div>
          { !submitted &&
            <Button
              action="primary"
              className="mt-5"
              color={isMs ? "purple" : "indigo"}
              disabled={disabled || submitted || selection < 0}
              loading={loading}
              onClick={handleSubmit}
              style={{ minWidth: "152px" }}
              title="Submit"
            >
              Submit
            </Button>
          }
          {submitted && !errors
            ? <p className="mt-5">
                Your response has been submitted! You selected:&nbsp;
                <span
                  className={clsx(
                    "font-bold",
                    {
                      "text-lime-800": responses[selection].color === "lime",
                      "text-yellow-900": responses[selection].color === "yellow",
                      "text-orange-800": responses[selection].color === "orange",
                      "text-red-900": responses[selection].color === "red",
                    }
                )}>
                  {responses[selection].text}
                </span>
                .
              </p>
            : submitted && errors
              ? <p className="mt-5">There was an error submitting your response.</p>
              : null
          }
        </div>
      </div>
    </div>
  );
};

export default Poll;

